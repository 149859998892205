import { SHARED } from '@/constants/constants'
import { IsActiveStatus, LockUnlockStatus, YesNoStatus, Gender, AgentPayoutMethod } from '@/constants/enums'
import {
  SlotsProvider,
  SportsProvider,
  CricketProvider,
  LiveProvider,
  TableProvider,
  LotteryProvider,
} from '@/constants/enums/provider.enum'
import { locale } from './localization-helper'
import { SocialContactType } from "@/constants/enums/social_contact_type.enum";

export const ddlHandler = {
  getOptionAll,
  getPageSize,
  getIsActiveStatus,
  getLockUnlockStatus,
  getYesNoStatus,
  getGenders,
  getLanguage,
  getPayoutMethod,
  getSocialContactType,
  getSportsProvider,
  getLiveProvider,
  getSlotsProvider,
  getTableProvider,
  getCricketProvider,
  getLotteryProvider,

  /*  */
  getAffiliateMemberIsActiveStatus
}

function getOptionAll(isStringVal = false) {
  return {
    displayText: locale.getMessage('label.all'),
    value: isStringVal ? '' : SHARED.DEFAULT_ALL_VALUE
  }
}

function getPayoutMethod(hasOptionAll) {
  let ddl = []

  if (hasOptionAll) {
    ddl.push(getOptionAll())
  }

  ddl.push({
    text: locale.getMessage(`agentPayoutMethod.${AgentPayoutMethod.BKASH}`),
    value: AgentPayoutMethod.BKASH
  })
  ddl.push({
    text: locale.getMessage(`agentPayoutMethod.${AgentPayoutMethod.NAGAD}`),
    value: AgentPayoutMethod.NAGAD
  })
  ddl.push({
    text: locale.getMessage(`agentPayoutMethod.${AgentPayoutMethod.ROCKET}`),
    value: AgentPayoutMethod.ROCKET
  })

  return ddl
}

function getSocialContactType(hasOptionAll) {
  let ddl = []

  if (hasOptionAll) {
    ddl.push(getOptionAll())
  }

  ddl.push({
    text: locale.getMessage(`agentSocialContactType.${SocialContactType.NONE}`),
    value: SocialContactType.NONE
  })
  ddl.push({
    text: locale.getMessage(`agentSocialContactType.${SocialContactType.TELEGRAM}`),
    value: SocialContactType.TELEGRAM
  })
  ddl.push({
    text: locale.getMessage(`agentSocialContactType.${SocialContactType.IMO}`),
    value: SocialContactType.IMO
  })
  ddl.push({
    text: locale.getMessage(`agentSocialContactType.${SocialContactType.WHATSAPP}`),
    value: SocialContactType.WHATSAPP
  })

  return ddl
}

function getAffiliateMemberIsActiveStatus() {
  let ddl = []

  ddl.push(getOptionAll(true))

  ddl.push({
    displayText: locale.getMessage(`affilateStatus.1`),
    value: 1
  })
  ddl.push({
    displayText: locale.getMessage(`affilateStatus.0`),
    value: 0
  })

  return ddl
}

function getPageSize(hasOptionAll) {
  let ddl = SHARED.PAGE_SIZE_LIST

  if (hasOptionAll) {
    ddl.push(getOptionAll())
  }

  return ddl
}

function getIsActiveStatus(hasOptionAll) {
  let ddl = []

  if (hasOptionAll) {
    ddl.push(getOptionAll())
  }

  ddl.push({
    text: locale.getMessage(`enumActivationStatus[0].${IsActiveStatus.ACTIVE}`),
    value: IsActiveStatus.ACTIVE
  })
  ddl.push({
    text: locale.getMessage(`enumActivationStatus[0].${IsActiveStatus.INACTIVE}`),
    value: IsActiveStatus.INACTIVE
  })

  return ddl
}

function getLockUnlockStatus(hasOptionAll) {
  let ddl = []

  if (hasOptionAll) {
    ddl.push(getOptionAll())
  }

  ddl.push({
    text: locale.getMessage(`enumLockUnlockStatus[0].${LockUnlockStatus.LOCKED}`),
    value: LockUnlockStatus.LOCKED
  })
  ddl.push({
    text: locale.getMessage(`enumLockUnlockStatus[0].${LockUnlockStatus.UNLOCKED}`),
    value: LockUnlockStatus.UNLOCKED
  })

  return ddl
}

function getYesNoStatus(hasOptionAll) {
  let ddl = []
  if (hasOptionAll) {
    ddl.push(getOptionAll())
  }
  ddl.push({
    text: locale.getMessage(`enumYesNoStatus[0].${YesNoStatus.YES}`),
    value: YesNoStatus.YES
  })
  ddl.push({
    text: locale.getMessage(`enumYesNoStatus[0].${YesNoStatus.NO}`),
    value: YesNoStatus.NO
  })

  return ddl
}

function getGenders(isStringValue = false) {
  let ddl = []

  ddl.push({
    text: locale.getMessage(`enumGender[0].${Gender.MALE}`),
    value: isStringValue ? Gender.MALE.toString() : Gender.MALE
  })
  ddl.push({
    text: locale.getMessage(`enumGender[0].${Gender.FEMALE}`),
    value: isStringValue ? Gender.FEMALE.toString() : Gender.FEMALE
  })

  return ddl
}

function getLanguage() {
  let ddl = []

  SHARED.SUPPORTED_LANGUAGES.forEach(lang => {
    ddl.push({
      text: locale.getMessage(`enumLanguage[0].${lang}`),
      value: lang
    })
  })

  return ddl
}

function getSportsProvider() {
  let ddl = []

  ddl.push({
    text: locale.getMessage(`enumSportsProvider[0].${SportsProvider.BTI}`),
    value: SportsProvider.BTI
  })

  ddl.push({
    text: locale.getMessage(`enumSportsProvider[0].${SportsProvider.IBC}`),
    value: SportsProvider.IBC
  })

  return ddl
}

function getLiveProvider() {
  let ddl = []

  ddl.push({
    text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.SEXY_V2}`),
    value: LiveProvider.SEXY_V2
  })
  ddl.push({
    text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.PP}`),
    value: LiveProvider.PP
  })
  ddl.push({
    text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.EVO}`),
    value: LiveProvider.EVO
  })
  ddl.push({
    text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.SPRIBE}`),
    value: LiveProvider.SPRIBE
  })
  ddl.push({
    text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.JILI}`),
    value: LiveProvider.JILI
  })

  return ddl
}

function getSlotsProvider() {
  let ddl = []

  ddl.push({
    text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.PNG}`),
    value: SlotsProvider.PNG
  })

  ddl.push({
    text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.HABA}`),
    value: SlotsProvider.HABA
  })

  ddl.push({
    text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.PP}`),
    value: SlotsProvider.PP
  })
  ddl.push({
    text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.SPG}`),
    value: SlotsProvider.SPG
  })

  ddl.push({
    text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.RT}`),
    value: SlotsProvider.RT
  })

  ddl.push({
    text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.JILI}`),
    value: SlotsProvider.JILI
  })

  return ddl
}

function getTableProvider() {
  let ddl = []

  ddl.push({
    text: locale.getMessage(`enumTableProvider[0].${TableProvider.SPRIBE}`),
    value: TableProvider.SPRIBE
  })

  ddl.push({
    text: locale.getMessage(`enumTableProvider[0].${TableProvider.JILI}`),
    value: TableProvider.JILI
  })

  ddl.push({
    text: locale.getMessage(`enumTableProvider[0].${TableProvider.KING_MAKER}`),
    value: TableProvider.KING_MAKER
  })
  return ddl
}

function getCricketProvider() {
  let ddl = []

  ddl.push({
    text: locale.getMessage(`enumCricketProvider[0].${CricketProvider.BETSWIZ}`),
    value: CricketProvider.BETSWIZ
  })

  ddl.push({
    text: locale.getMessage(`enumCricketProvider[0].${CricketProvider.SAP}`),
    value: CricketProvider.SAP
  })

  return ddl
}

function getLotteryProvider() {
  let ddl = []

  ddl.push({
    text: locale.getMessage(`enumLotteryProvider[0].${LotteryProvider.SPRIBE}`),
    value: LotteryProvider.SPRIBE
  })

  ddl.push({
    text: locale.getMessage(`enumLotteryProvider[0].${LotteryProvider.IBC}`),
    value: LotteryProvider.IBC
  })

  return ddl
}
